<template>
	<div
		:id="notification.id.toString()"
		class="grid w-80 max-w-xs grid-cols-4 flex-row items-center gap-3 rounded bg-white shadow-md"
	>
		<IconsClose
			class="absolute right-1 top-1 z-10 h-3 w-3 cursor-pointer"
			@click="$emit('close')"
		/>

		<!-- Icons -->
		<div
			class="flex h-full items-center justify-center rounded-bl rounded-tl"
			:class="notification.type.toLowerCase()"
		>
			<ExclamationTriangleIcon v-if="type === 'error'" class="h-10 w-10 stroke-white" />
			<InformationCircleIcon v-if="type === 'info'" class="h-10 w-10 stroke-white" />
			<CheckCircleIcon v-if="type === 'success'" class="h-10 w-10 stroke-white" />
			<ExclamationCircleIcon v-if="type === 'warning'" class="h-10 w-10 stroke-white" />
		</div>

		<!-- Text -->
		<div class="col-span-3 px-1 py-2">
			<p class="font-bold capitalize">{{ notification.type }}</p>
			<p class="text-sm">{{ notification.message }}</p>
		</div>
	</div>
</template>

<script setup lang="ts">
import IconsClose from '../../icons/Close.vue';
import ExclamationTriangleIcon from '@heroicons/vue/24/outline/ExclamationTriangleIcon';
import ExclamationCircleIcon from '@heroicons/vue/24/outline/ExclamationCircleIcon';
import InformationCircleIcon from '@heroicons/vue/24/outline/InformationCircleIcon';
import CheckCircleIcon from '@heroicons/vue/24/outline/CheckCircleIcon';
import { computed } from 'vue';
import type { Notification } from '~/types/app.types';

const props = defineProps<{
	notification: Notification;
}>();

const type = computed(() => props.notification.type.toLowerCase());

defineEmits(['close']);
</script>

<style scoped>
.info {
	/* border-left: 8px solid #9e9e9e; */
	background-color: #9e9e9e;
}
.success {
	/* border-left: 8px solid #00ba38; */
	/* background-color: #00ba38; */
	@apply bg-cta;
}
.warning {
	/* border-left: 8px solid #deab02; */
	background-color: #deab02;
}
.error {
	/* border-left: 8px solid #c10303; */
	background-color: #c10303;
}
</style>
