<template>
	<Teleport to="body">
		<Transition name="menu">
			<div
				v-if="menuOpen"
				class="fixed left-0 top-0 z-40 flex h-full w-full p-8"
				@click.self="$emit('modalClose')"
			>
				<div class="absolute right-0 top-16 z-10 rounded-bl bg-neutral-900 py-8">
					<div
						class="group flex cursor-pointer flex-row items-center gap-2 px-6 text-zinc-100 transition duration-200"
						@click="UserStore.logout()"
					>
						Sign Out
						<ArrowRightOnRectangleIcon
							class="w-5 stroke-slate-400 transition duration-200 group-hover:stroke-slate-100"
						/>
					</div>
				</div>
			</div>
		</Transition>
	</Teleport>
</template>

<script setup lang="ts">
import ArrowRightOnRectangleIcon from '@heroicons/vue/24/outline/ArrowRightOnRectangleIcon';
import { useUserStore } from '../../../stores/userStore';

defineProps<{ menuOpen: boolean }>();

defineEmits(['modalClose']);

const UserStore = useUserStore();
</script>

<style scope>
.menu-enter-active,
.menu-leave-active {
	transition: all 0.3s ease-in-out;
}

.menu-enter-from,
.menu-leave-to {
	transform: translateX(200px);
}
</style>
