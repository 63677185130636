<template>
	<div class="flex h-16 items-center justify-end gap-4 border-b border-b-grey-light p-5">
		<ManagerAndAdminOnly>
			<NuxtLink
				to="https://forms.gle/idNRLBncjM8VDnG39"
				target="_blank"
				class="text-xs text-zinc-500 transition hover:text-zinc-950"
			>
				Report Bug / Suggest UX/UI Improvement
			</NuxtLink>
		</ManagerAndAdminOnly>
		<DashboardOrderNotification />
		<IconsUser class="btn h-8 w-8 cursor-pointer" @click="menuOpen = !menuOpen" />
	</div>

	<AccountDropDownMenu :menu-open="menuOpen" @modal-close="menuOpen = false" />
</template>

<script setup>
import AccountDropDownMenu from './dashboard/account/AccountDropDownMenu.vue';
import DashboardOrderNotification from './dashboard/DashboardOrderNotification.vue';
import ManagerAndAdminOnly from './dashboard/access control/ManagerAndAdminOnly.vue';

const menuOpen = ref(false);
</script>
