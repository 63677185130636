<template>
	<div>
		<SpeakerWaveIcon
			v-if="soundOn"
			class="w-6 cursor-pointer stroke-2"
			@click="menuOpen = true"
		/>
		<SpeakerXMarkIcon v-else class="w-6 cursor-pointer stroke-2" @click="menuOpen = true" />
	</div>

	<Teleport to="body">
		<Transition name="sound">
			<div
				v-if="menuOpen"
				class="fixed left-0 top-0 z-40 flex h-full w-full p-8"
				@click.self="menuOpen = false"
			>
				<div
					class="absolute right-0 top-16 z-10 flex flex-col gap-2 rounded-bl bg-neutral-900 px-4 py-6"
				>
					<div class="flex items-center gap-2">
						<DashboardToggle
							:enabled="soundOn"
							class="scale-75"
							@toggle="soundOn = !soundOn"
						/>
						<p class="text-sm text-white">Play order notification sound</p>
					</div>

					<div class="flex items-center gap-2">
						<DashboardToggle
							:enabled="showNotification"
							class="scale-75"
							@toggle="showNotification = !showNotification"
						/>
						<p class="text-sm text-white">Show order notification</p>
					</div>
				</div>
			</div>
		</Transition>
	</Teleport>
</template>

<script setup lang="ts">
import { useNotificationStore } from '~/stores/notificationStore';
import wav from '~/assets/sounds/new-order-4.wav';
import SpeakerWaveIcon from '@heroicons/vue/24/outline/SpeakerWaveIcon';
import SpeakerXMarkIcon from '@heroicons/vue/24/outline/SpeakerXMarkIcon';
import DashboardToggle from './ui/DashboardToggle.vue';
import type { Database } from '~/types/api/supabase.types';
import type { RealtimeChannel } from '@supabase/supabase-js';

const NotificationStore = useNotificationStore();

const soundOn = ref(true);
const showNotification = ref(true);
const menuOpen = ref(false);

const supabase = useSupabaseClient<Database>();
let preordersChannel: RealtimeChannel | null = null;

function notificationSound() {
	if (!soundOn.value) return;
	const audio = new Audio(wav);
	audio.volume = 1;
	audio.play();
}

function notification() {
	if (!showNotification.value) return;
	NotificationStore.createNotification({
		type: 'info',
		message: 'A new order has been placed.',
	});
}

function saveSettingsToLocalStorage() {
	const settings = {
		sound: soundOn.value,
		notification: showNotification.value,
	};
	localStorage.setItem('new_order_alert_settings', JSON.stringify(settings));
}

function populateSettingsLocalStorage() {
	const settings = localStorage.getItem('new_order_alert_settings');
	if (settings) {
		const parsedSettings = JSON.parse(settings);
		soundOn.value = parsedSettings.sound;
		showNotification.value = parsedSettings.notification;
	}
}

watch(
	[soundOn, showNotification],
	() => {
		saveSettingsToLocalStorage();
	},
	{ deep: true },
);

onMounted(() => {
	if (import.meta.client) {
		populateSettingsLocalStorage();
	}

	preordersChannel = supabase
		.channel('preordersChannel')
		.on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'preorders' }, () => {
			notificationSound();
			notification();
		})
		.subscribe();
});

onUnmounted(() => {
	try {
		if (preordersChannel) {
			supabase.removeChannel(preordersChannel);
		}
	} catch (error) {
		console.error('Error unsubscribing from sync channel:', error);
	}
});
</script>

<style scope>
.sound-enter-active,
.sound-leave-active {
	transition: all 0.3s ease-in-out;
}

.sound-enter-from,
.sound-leave-to {
	transform: translateX(300px);
}
</style>
