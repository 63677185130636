<template>
	<div class="hidden lg:block">
		<TheDashboardSideBar />
		<div id="dashboard">
			<TheDashboardTopBar />
			<main id="dashboard-body" class="no-scrollbar">
				<DashboardNotificationProvider />
				<slot></slot>
			</main>
		</div>
	</div>

	<div
		class="flex h-[100vh] w-[100vw] flex-col items-center justify-center bg-gray-800 text-center lg:hidden"
	>
		<LogomarkFrown class="mb-4 w-12" />
		<p class="text-white">The dashboard is currently only available on desktop devices.</p>
	</div>
</template>

<script setup>
import DashboardNotificationProvider from '../components/dashboard/ui/DashboardNotificationProvider.vue';
import { useMediaLibraryStore } from '../stores/mediaLibraryStore';
import LogomarkFrown from '../components/icons/LogomarkFrown.vue';

const MediaLibraryStore = useMediaLibraryStore();

MediaLibraryStore.fetchImages();
</script>

<style>
#dashboard {
	width: calc(100vw - 240px);
	float: right;
}
#dashboard-body {
	padding: 20px;
	max-height: calc(100vh - 4rem);
	overflow-y: scroll;
}
textarea:focus,
input:focus {
	outline: none;
}
*:focus {
	outline: none;
}
</style>
