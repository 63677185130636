<template>
	<svg id="icon--user" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<path
				d="M12,12c-2.21,0-4-1.79-4-4s1.79-4,4-4,4,1.79,4,4-1.79,4-4,4Zm0-6.5c-1.38,0-2.5,1.12-2.5,2.5s1.12,2.5,2.5,2.5,2.5-1.12,2.5-2.5-1.12-2.5-2.5-2.5Z"
			/>
			<path
				d="M17.15,20H6.85c-.89,0-1.7-.43-2.11-1.12-.36-.6-.38-1.32-.04-1.97,.88-1.67,2.86-3.66,7.3-3.66s6.42,1.99,7.3,3.66c.34,.65,.33,1.36-.04,1.97-.42,.69-1.23,1.12-2.11,1.12Zm-5.15-5.25c-3.69,0-5.29,1.55-5.98,2.86-.09,.18-.1,.34,0,.49,.12,.2,.42,.4,.83,.4h10.3c.41,0,.71-.2,.83-.4,.09-.15,.09-.31,0-.49-.69-1.3-2.28-2.86-5.98-2.86Z"
			/>
		</svg>
	</svg>
</template>
